import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import "./appSettingUi.css";
import webApi from "../api/webApi";
import fileApi from "../api/fileApi";
import appEnums from "../appEnums";
import Confirm from "../customAlerts/confirm";
import AppMenuUi from "../appMenu/appMenuUi";
import commonApi from "../api/commonApi";
import AppEvent from "../appEvent";
import * as emoji from "node-emoji";

const { iOS, Unity, AndroidGoogle, AndroidAmazon, None } = appEnums.Platform;

class AppSettingUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            platform: None,
            appName: "",
            appIcon: "",
            adminLink: "",
            adminLinkOrigin: "",
            adminLinkPath: "",
            passwordHash: "",
            appStoreLink: "",
            appKey: "",
            googleFcmServerKey: "",
            googleServiceAccountFilename: "",
            apnFilenameDev: "",
            apnPasswordDev: "",
            apnFilenameRelease: "",
            apnPasswordRelease: "",

            googleServiceAccountData: "",
            apnFileDevData: "",
            apnFileReleaseData: "",

            error: "",
            isDeleteAppClick: false,
            isAppMenuOpen: false,
            updateAdminLinkOriginForAll: false,
            updatePwHashForAll: false,

            initialPlatform: None,
            initialAppName: "",
            initialAppIcon: "",
            initialAdminLink: "",
            initialAdminLinkOrigin: "",
            initialAdminLinkPath: "",
            initialPasswordHash: "",
            initialAppStoreLink: "",
            initialAppKey: "",
            initialGoogleFcmServerKey: "",
            initialGoogleServiceAccountFilename: "",
            initialApnFilenameDev: "",
            initialApnPasswordDev: "",
            initialApnFilenameRelease: "",
            initialApnPasswordRelease: ""
        };
        this.oldGoogleServiceAccountFilename = "";
        this.oldAPNFilenameDev = "";
        this.oldAPNFilenameRelease = "";

        this.handleAppNameChange = event => this.setState({ appName: event.target.value });
        this.handleAppIconChange = event => this.setState({ appIcon: event.target.value });
        this.handleAdminLinkPathChange = event => this.setState({ adminLinkPath: event.target.value });
        this.handleAdminLinkOriginChange = event => this.setState({ adminLinkOrigin: event.target.value });
        this.handlePasswordHashChange = event => this.setState({ passwordHash: event.target.value });
        this.handleAppStoreLinkChange = event => this.setState({ appStoreLink: event.target.value });
        this.handleServerKeyChange = event => this.setState({ googleFcmServerKey: event.target.value });
        this.handleServiceAccountChange = event => this.setState({ googleServiceAccountFilename: event.target.value });
        this.handleApnPasswordDevChange = event => this.setState({ apnPasswordDev: event.target.value });
        this.handleApnPasswordReleaseChange = event => this.setState({ apnPasswordRelease: event.target.value });
        this.handleUpdateAdminLinkOriginForAll = event => this.setState({ updateAdminLinkOriginForAll: !this.state.updateAdminLinkOriginForAll });
        this.handleUpdatePwHashForAll = event => this.setState({ updatePwHashForAll: !this.state.updatePwHashForAll });
        this.dismissError = () => this.setState({ error: "" });
        this.onAbortDeleteApp = () => this.setState({ isDeleteAppClick: false });
        this.onClickDeleteApp = () => this.setState({ isDeleteAppClick: true });
    }

    isSomethingChange = () => {
        const {
            platform,
            appName,
            appIcon,
            adminLink,
            adminLinkOrigin,
            adminLinkPath,
            passwordHash,
            appStoreLink,
            appKey,
            googleFcmServerKey,
            googleServiceAccountFilename,
            apnFilenameDev,
            apnPasswordDev,
            apnFilenameRelease,
            apnPasswordRelease,

            initialPlatform,
            initialAppName,
            initialAppIcon,
            initialAdminLink,
            initialAdminLinkOrigin,
            initialAdminLinkPath,
            initialPasswordHash,
            initialAppStoreLink,
            initialAppKey,
            initialGoogleFcmServerKey,
            initialGoogleServiceAccountFilename,
            initialApnFilenameDev,
            initialApnPasswordDev,
            initialApnFilenameRelease,
            initialApnPasswordRelease
        } = this.state;
        const somethingChange =
            platform !== initialPlatform ||
            appName !== initialAppName ||
            appIcon !== initialAppIcon ||
            adminLink !== initialAdminLink ||
            adminLinkOrigin !== initialAdminLinkOrigin ||
            adminLinkPath !== initialAdminLinkPath ||
            passwordHash !== initialPasswordHash ||
            appStoreLink !== initialAppStoreLink ||
            appKey !== initialAppKey ||
            googleFcmServerKey !== initialGoogleFcmServerKey ||
            googleServiceAccountFilename !== initialGoogleServiceAccountFilename ||
            apnFilenameDev !== initialApnFilenameDev ||
            apnPasswordDev !== initialApnPasswordDev ||
            apnFilenameRelease !== initialApnFilenameRelease ||
            apnPasswordRelease !== initialApnPasswordRelease;
        return somethingChange;
    };

    componentDidUpdate(prevProps, prevState) {
        const { appEvent, token, bundleId } = this.props;
        if (appEvent) {
            if (this.isSomethingChange()) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }

        if (prevProps.bundleId !== bundleId) {
            webApi
                .fetchGetAppSetting(bundleId, token)
                .then(this.onFetchAppSetting)
                .catch(errDict => this.setState({ error: errDict.error }));
        }
    }

    onChangeApp = bundleId => {
        const { onChangeApp } = this.props;
        if (onChangeApp === null) return;
        onChangeApp(bundleId);
    };

    componentDidMount() {
        const { bundleId, token } = this.props;
        this.setState({ bundleId: bundleId }, () => {
            webApi
                .fetchGetAppSetting(bundleId, token)
                .then(this.onFetchAppSetting)
                .catch(errDict => this.setState({ error: errDict.error }));
        });
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }
    }

    handleCancel = () => {
        const { bundleId, token } = this.props;
        webApi
            .fetchGetAppSetting(bundleId, token)
            .then(this.onFetchAppSetting)
            .then(() => this.setState({ error: "Changes Canceled" }))
            .catch(errDict => this.setState({ error: errDict.error }));
    };

    onFetchAppSetting = resDict => {
        let adminLinkOrigin = '';
        let adminLinkPath = '';

        if (commonApi.canParseURL(resDict.adminLink)) {
            const url = new URL(resDict.adminLink);
            adminLinkOrigin = url.origin;
            adminLinkPath = url.toString().slice(url.origin.length);
        } else {
            adminLinkPath = resDict.adminLink;
        }

        this.setState(
            {
                platform: commonApi.isInteger(resDict.platform) ? parseInt(resDict.platform) : resDict.platform,
                appName: resDict.appName,
                appIcon: resDict.appIcon,
                adminLink: resDict.adminLink,
                adminLinkOrigin,
                adminLinkPath,
                passwordHash: resDict.passwordHash,
                appStoreLink: resDict.appStoreLink,
                appKey: resDict.appKey,
                googleFcmServerKey: resDict.googleFcmServerKey,
                googleServiceAccountFilename: resDict.googleServiceAccountFilename,
                apnFilenameDev: resDict.apnFilenameDev,
                apnPasswordDev: resDict.apnPasswordDev,
                apnFilenameRelease: resDict.apnFilenameRelease,
                apnPasswordRelease: resDict.apnPasswordRelease,

                initialPlatform: commonApi.isInteger(resDict.platform) ? parseInt(resDict.platform) : resDict.platform,
                initialAppName: resDict.appName,
                initialAppIcon: resDict.appIcon,
                initialAdminLink: resDict.adminLink,
                initialAdminLinkOrigin: adminLinkOrigin,
                initialAdminLinkPath: adminLinkPath,
                initialPasswordHash: resDict.passwordHash,
                initialAppStoreLink: resDict.appStoreLink,
                initialAppKey: resDict.appKey,
                initialGoogleFcmServerKey: resDict.googleFcmServerKey,
                initialGoogleServiceAccountFilename: resDict.googleServiceAccountFilename,
                initialApnFilenameDev: resDict.apnFilenameDev,
                initialApnPasswordDev: resDict.apnPasswordDev,
                initialApnFilenameRelease: resDict.apnFilenameRelease,
                initialApnPasswordRelease: resDict.apnPasswordRelease
            },
            () => {
                this.oldGoogleServiceAccountFilename = this.state.googleServiceAccountFilename;
                this.oldAPNFilenameDev = this.state.apnFilenameDev;
                this.oldAPNFilenameRelease = this.state.apnFilenameRelease;
            }
        );
    };

    handlePlatformChange = event => {
        let platform = event.target.value;
        if (commonApi.isInteger(platform)) {
            platform = parseInt(platform);
        }
        this.setState({ platform: platform });
    };

    handleApnPasswordReleaseChange(event) {
        this.setState({ apnPasswordRelease: event.target.value });
    }

    onDeleteAPNDevFilename = event => {
        this.setState({ apnFilenameDev: "", apnPasswordDev: "", apnFileDevData: "" });
        if (this.refAppSettingSelectDevFileButton) {
            this.refAppSettingSelectDevFileButton.value = null;
        }
    };

    onSelectAPNDevFile = event => {
        if (event.target.files.length > 0) {
            const fileName = event.target.files[0].name;
            fileApi
                .convertFileToArrayBuffer(event.target.files[0])
                .then(arrayBuffer => {
                    this.setState({ apnFilenameDev: fileName, apnFileDevData: arrayBuffer });
                })
                .catch(errDict => {
                    const { appEvent } = this.props;
                    if (appEvent) {
                        appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                    }
                });
        }
    };

    onDeleteAPNReleaseFilename = event => {
        this.setState({ apnFilenameRelease: "", apnPasswordRelease: "", apnFileReleaseData: "" });
        if (this.refAppSettingSelectReleaseFileButton) {
            this.refAppSettingSelectReleaseFileButton.value = null;
        }
    };

    onSelectAPNReleaseFile = event => {
        if (event.target.files.length > 0) {
            const fileName = event.target.files[0].name;
            fileApi
                .convertFileToArrayBuffer(event.target.files[0])
                .then(arrayBuffer => {
                    this.setState({ apnFilenameRelease: fileName, apnFileReleaseData: arrayBuffer });
                })
                .catch(errDict => {
                    const { appEvent } = this.props;
                    if (appEvent) {
                        appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                    }
                });
        }
    };

    onDeleteGoogleServiceAccountFilename = event => {
        this.setState({
            googleServiceAccountFilename: "",
            googleServiceAccountData: "",
        });
        if (this.refAppSettingGoogleServiceAccountFileButton) {
            this.refAppSettingGoogleServiceAccountFileButton.value = null;
        }
    };

    onSelectGoogleServiceAccountFile = event => {
        if (event.target.files.length > 0) {
            const fileName = event.target.files[0].name;
            fileApi
                .convertFileToArrayBuffer(event.target.files[0])
                .then(arrayBuffer => {
                    this.setState({
                        googleServiceAccountFilename: fileName,
                        googleServiceAccountData: arrayBuffer
                    });
                })
                .catch(err => {
                    const { appEvent } = this.props;
                    appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, err)
                });
        }
    };

    handleSubmit = event => {
        event.preventDefault();

        const { apnFilenameDev, googleServiceAccountFilename, apnFilenameRelease, apnPasswordDev, apnPasswordRelease, appName, adminLinkOrigin, adminLinkPath } = this.state;

        if (!appName) {
            return this.setState({ error: "App name is required" });
        }

        if (!apnFilenameDev && apnPasswordDev) {
            return this.setState({ error: "APNs development filename is required" });
        }

        if (apnFilenameDev && !apnPasswordDev) {
            return this.setState({ error: "APNs development filename password is required" });
        }

        if (!apnFilenameRelease && apnPasswordRelease) {
            return this.setState({ error: "APNs filename is required" });
        }

        if (apnFilenameRelease && !apnPasswordRelease) {
            return this.setState({ error: "APNs filename password is required" });
        }

        if (apnFilenameDev && apnFilenameRelease && apnFilenameDev === apnFilenameRelease) {
            return this.setState({ error: "APNs file names can not the same" });
        }

        if (googleServiceAccountFilename && (googleServiceAccountFilename === apnFilenameDev || googleServiceAccountFilename === apnFilenameRelease)) {
            return this.setState({ error: "Google Service Account file name must not be the same with APNs development or release" });
        }

        if (adminLinkOrigin && !adminLinkPath) {
            return this.setState({ error: "Admin Link Path is required" });
        }

        if (!adminLinkOrigin && adminLinkPath) {
            return this.setState({ error: "Admin Link Origin is required" });
        }

        if (adminLinkOrigin && !commonApi.canParseURL(adminLinkOrigin)) {
            return this.setState({ error: "Admin Link Origin must be a valid URL" });
        }

        if (adminLinkOrigin && adminLinkOrigin.endsWith('/')) {
            return this.setState({ error: "Admin Link Origin must not end with '/'" });
        }

        if (adminLinkOrigin && new URL(adminLinkOrigin).origin !== adminLinkOrigin) {
            return this.setState({ error: "Admin Link Origin must only contain the origin segment of a URL" });
        }

        if (adminLinkPath && !adminLinkPath.startsWith('/')) {
            return this.setState({ error: "Admin Link Path must start with '/'" });
        }

        if (adminLinkOrigin && adminLinkPath && !commonApi.canParseURL(adminLinkPath, adminLinkOrigin)) {
            return this.setState({ error: "Admin Link Origin & Admin Link Path combined did not result in a valid URL" });
        }

        const adminLink = (adminLinkOrigin && adminLinkPath)
            ? new URL(adminLinkPath, adminLinkOrigin).toString()
            : '';

        const { bundleId, token, hcCompanyId } = this.props;
        this.setState({ error: "Start saving..." });

        const metaData = {
            platform: this.state.platform,
            appIcon: emoji.strip(this.state.appIcon),
            adminLink: emoji.strip(adminLink),
            passwordHash: emoji.strip(this.state.passwordHash),
            appStoreLink: emoji.strip(this.state.appStoreLink),
            googleFcmServerKey: emoji.strip(this.state.googleFcmServerKey),
            googleServiceAccountFilename: emoji.strip(this.state.googleServiceAccountFilename),
            apnFilenameDev: this.state.apnFilenameDev,
            apnPasswordDev: this.state.apnPasswordDev,
            apnFilenameRelease: this.state.apnFilenameRelease,
            apnPasswordRelease: this.state.apnPasswordRelease
        };
        const stringifiedMetadata = JSON.stringify(metaData);
        webApi
            .fetchChangeAppSetting(bundleId, this.state.appName, stringifiedMetadata, token)
            .then(resDict => {
                this.setState(
                    {
                        adminLink,
                        initialAppName: this.state.appName,
                        initialPlatform: this.state.platform,
                        initialAppIcon: this.state.appIcon,
                        initialAdminLink: adminLink,
                        initialAdminLinkOrigin: adminLinkOrigin,
                        initialAdminLinkPath: adminLinkPath,
                        initialPasswordHash: this.state.passwordHash,
                        initialAppStoreLink: this.state.appStoreLink,
                        initialGoogleFcmServerKey: this.state.googleFcmServerKey,
                        initialGoogleServiceAccountFilename: this.state.googleServiceAccountFilename,
                        initialApnFilenameDev: this.state.apnFilenameDev,
                        initialApnPasswordDev: this.state.apnPasswordDev,
                        initialApnFilenameRelease: this.state.apnFilenameRelease,
                        initialApnPasswordRelease: this.state.apnPasswordRelease
                    },
                    () => {
                        this.props.appInfoDict[bundleId].appName = this.state.appName;
                        this.props.onSaveAppSetting();
                        this.uploadGoogleServiceAccountFile(resDict);
                    }
                );
            })
            .then(() => {
                if (this.state.updatePwHashForAll) {
                    return webApi.fetchUpdatePasswordHashForAllApps(hcCompanyId, this.state.passwordHash, token).then(() => {
                        this.setState({ updatePwHashForAll: false });
                    });
                }
                return true;
            })
            .then(() => {
                if (this.state.updateAdminLinkOriginForAll && commonApi.canParseURL(adminLink)) {
                    return webApi.fetchUpdateAdminLinkOriginForAllApps(hcCompanyId, adminLinkOrigin, token).then(() => {
                        this.setState({ updateAdminLinkOriginForAll: false });
                    });
                }
                return true;
            })
            .catch(errDict => this.setState({ error: errDict.error }));
    };

    uploadGoogleServiceAccountFile = resDict => {
        if ("error" in resDict) {
            this.setState({ error: `Update Google service account file error=${resDict.error}` });
            return;
        }

        if (this.oldGoogleServiceAccountFilename || this.state.googleServiceAccountFilename) {
            this.setState({ error: "Update Google Service Account file..." });
            const { hcCompanyId, bundleId, appWebSocket } = this.props;
            appWebSocket
                .updateGoogleServiceAccountFile(hcCompanyId, bundleId, this.oldGoogleServiceAccountFilename, this.state.googleServiceAccountFilename, this.state.googleServiceAccountData)
                .then(this.uploadAPNDevFile)
                .catch(err => this.setState({ error: err.error }));
        } else {
            this.uploadAPNDevFile({})
        }
    };

    uploadAPNDevFile = resDict => {
        if ("error" in resDict) {
            this.setState({ error: "Update APNs development file error=" + resDict.error });
            return;
        }

        this.oldGoogleServiceAccountFilename = this.state.googleServiceAccountFilename;

        if (this.oldAPNFilenameDev || this.state.apnFilenameDev) {
            this.setState({ error: "Update APNs development file..." });
            const { hcCompanyId, bundleId, appWebSocket } = this.props;
            appWebSocket
                .updateAPNFiles(hcCompanyId, bundleId, this.oldAPNFilenameDev, this.state.apnFilenameDev, this.state.apnFileDevData, true)
                .then(this.uploadAPNReleaseFile)
                .catch(errDict => this.setState({ error: errDict.error }));
        } else {
            this.uploadAPNReleaseFile({});
        }
    };

    uploadAPNReleaseFile = resDict => {
        if ("error" in resDict) {
            return this.setState({ error: "Update APNs file error=" + resDict.error });
        }

        this.oldAPNFilenameDev = this.state.apnFilenameDev;

        if (this.oldAPNFilenameRelease || this.state.apnFilenameRelease) {
            const { hcCompanyId, bundleId, appWebSocket } = this.props;
            this.setState({ error: "Update APNs file..." });
            appWebSocket
                .updateAPNFiles(
                    hcCompanyId,
                    bundleId,
                    this.oldAPNFilenameRelease,
                    this.state.apnFilenameRelease,
                    this.state.apnFileReleaseData,
                    false
                )
                .then(this.onChangeAppSetting)
                .catch(errDict => this.setState({ error: errDict.error }));
        } else {
            this.onChangeAppSetting({});
        }
    };

    onChangeAppSetting = resDict => {
        this.oldAPNFilenameRelease = this.state.apnFilenameRelease;
        this.setState({ error: "Saved" });
    };

    onDeleteApp = () => {
        const { onDeleteAppCallBack } = this.props;
        onDeleteAppCallBack();
        this.onAbortDeleteApp();
    };

    onConfirmDeleteApp = () => {
        const { hcCompanyId, bundleId, token } = this.props;
        webApi
            .fetchDeleteApp(hcCompanyId, bundleId, token)
            .then(this.onDeleteApp)
            .then(() => this.onChangeApp(this.props.bundleId))
            .catch(errDict => this.setState({ error: errDict.error }));
    };

    render() {
        const { appInfoDict, onClickCreateAppButton, bundleId, role, token, hcCompanyId } = this.props;
        const platformInt = parseInt(this.state.platform);
        const appPopupComponent = (
            <AppMenuUi
                hcCompanyId={hcCompanyId}
                appInfoDict={appInfoDict}
                bundleId={bundleId}
                onClickCreateAppButton={onClickCreateAppButton}
                onChangeApp={this.onChangeApp}
                token={token}
                role={role}
            />
        );

        const saveButtonUi = (
            <button id="appSettingSaveButton" type="submit" alt="Save Changes" title="Save Changes" className="app-setting-button">
                SAVE
            </button>
        );

        const cancelButtonUi = (
            <button
                id="appSettingCancelButton"
                alt="Cancel Changes"
                type="reset"
                className="app-setting-button"
                onClick={this.handleCancel}>
                CANCEL
            </button>
        );

        const deleteButtonUi = (
            <input
                id="appSettingDeleteButton"
                type="image"
                src="/images/settings/btn_delete.png"
                alt="delete this app"
                onClick={this.onClickDeleteApp}
            />
        );

        const deleteAPNDevFilenameUi = this.state.apnFilenameDev ? (
            <button
                className="btn btn-sm btn-outline-dark"
                id="appSettingAPNDeleteDevFilenameButton"
                type="button"
                onClick={this.onDeleteAPNDevFilename}>
                Delete
            </button>
        ) : null;

        const deleteAPNReleaseFilenameUi = this.state.apnFilenameRelease ? (
            <button
                className="btn btn-sm btn-outline-dark"
                id="appSettingAPNDeleteReleaseFilenameButton"
                type="button"
                onClick={this.onDeleteAPNReleaseFilename}>
                Delete
            </button>
        ) : null;

        const googleTable =
            platformInt === AndroidGoogle || platformInt === AndroidAmazon || platformInt === None || platformInt === Unity ? (
                <table id="androidTable" className="slide-up-animation">
                    <tbody>
                        <tr>
                            <td>
                                <label className="AppSettingAndroidTitle">
                                    <b>Android&nbsp;(Google)</b>
                                </label>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Google Service Account JSON</td>
                            <td>
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <input
                                      id="appSettingGoogleServiceAccountFilenameInput"
                                      className="app-setting-text-input"
                                      value={this.state.googleServiceAccountFilename}
                                      readOnly
                                    />
                                    {this.state.googleServiceAccountFilename &&
                                        <button
                                            className="btn btn-sm btn-outline-dark"
                                            id="appSettingGoogleServiceAccountFilenameDeleteButton"
                                            type="button"
                                            onClick={this.onDeleteGoogleServiceAccountFilename}
                                        >
                                            Delete
                                        </button>}
                                    <input
                                        id="appSettingGoogleServiceAccountFileButton"
                                        type="file"
                                        ref={node => this.refAppSettingGoogleServiceAccountFileButton = node}
                                        accept=".json"
                                        className="file-input"
                                        onClick={event => event.target.value = null}
                                        onChange={this.onSelectGoogleServiceAccountFile}
                                    />
                                    <label htmlFor="appSettingGoogleServiceAccountFileButton">
                                        <span>Browse</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Google FCM Server Key (<a href="https://firebase.google.com/support/faq#deprecated-api-shutdown" target="_blank" rel="noreferrer" className="btn-link">deprecated</a>)&nbsp;</span>
                            </td>
                            <td>
                                <input
                                    id="googleFcmServerKeyInput"
                                    value={this.state.googleFcmServerKey}
                                    onChange={this.handleServerKeyChange}
                                    className="app-setting-text-input"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : null;

        const appleTable =
            platformInt === iOS || platformInt === None || platformInt === Unity ? (
                <table id="iosTable" className="slide-up-animation">
                    <tbody>
                        <tr>
                            <td>
                                <label className="AppSettingIOSTitle">
                                    <b>Apple&nbsp;(iOS)</b>
                                </label>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>APNs Development Filename</td>
                            <td>
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <input
                                        id="appSettingAPNDevFilenameInput"
                                        className="app-setting-text-input"
                                        value={this.state.apnFilenameDev}
                                        readOnly
                                    />
                                    {deleteAPNDevFilenameUi}
                                    <input
                                        id="appSettingSelectDevFileButton"
                                        type="file"
                                        ref={node => this.refAppSettingSelectDevFileButton = node}
                                        accept=".p12"
                                        className="file-input"
                                        onClick={event => {
                                            event.target.value = null;
                                        }}
                                        onChange={this.onSelectAPNDevFile}
                                    />
                                    <label htmlFor="appSettingSelectDevFileButton">
                                        <span>Browse</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>APNs Development Filename Password</td>
                            <td>
                                <input
                                    id="appSettingAPNDevPasswordInput"
                                    type="password"
                                    className="app-setting-text-input"
                                    value={this.state.apnPasswordDev}
                                    onChange={this.handleApnPasswordDevChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>APNs Filename</td>
                            <td>
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <input
                                        id="appSettingAPNReleaseFilenameInput"
                                        className="app-setting-text-input"
                                        value={this.state.apnFilenameRelease}
                                        readOnly
                                    />
                                    {deleteAPNReleaseFilenameUi}
                                    <input
                                        id="appSettingSelectReleaseFileButton"
                                        type="file"
                                        ref={node => this.refAppSettingSelectReleaseFileButton = node}
                                        accept=".p12"
                                        className="file-input"
                                        onClick={event => {
                                            event.target.value = null;
                                        }}
                                        onChange={this.onSelectAPNReleaseFile}
                                    />
                                    <label htmlFor="appSettingSelectReleaseFileButton">Browse</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>APNs Filename Password</td>
                            <td>
                                <input
                                    id="appSettingAPNReleasePasswordInput"
                                    type="password"
                                    value={this.state.apnPasswordRelease}
                                    className="app-setting-text-input"
                                    onChange={this.handleApnPasswordReleaseChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : null;

        return (
            <div className="ui1-container-div-lg slide-up-animation background-color-v2">
                {bundleId && (
                    <div id="appSettingPage">
                        <div className="app-setting-menu-div">{appPopupComponent}</div>
                        {deleteButtonUi}
                        <span className="app-setting-input-label">App Name</span>
                        <input
                            id="appNameInput"
                            value={this.state.appName}
                            onChange={this.handleAppNameChange}
                            className="app-setting-text-input"
                        />
                        <span className="app-setting-input-label">Platform</span>
                        <select
                            id="platformSelect"
                            value={this.state.platform}
                            onChange={this.handlePlatformChange}
                            className="app-setting-text-input">
                            {Object.keys(appEnums.Platform).map(key => {
                                return (
                                    <option value={appEnums.Platform[key]} key={key}>
                                        {key}
                                    </option>
                                );
                            })}
                        </select>
                        <span className="app-setting-input-label">App Icon</span>
                        <input
                            id="appIconInput"
                            value={this.state.appIcon}
                            onChange={this.handleAppIconChange}
                            className="app-setting-text-input"
                        />
                        <span className="app-setting-input-label">Admin Link Origin</span>
                        <input
                            id="adminLinkOriginInput"
                            value={this.state.adminLinkOrigin}
                            onChange={this.handleAdminLinkOriginChange}
                            type="text"
                            className="app-setting-text-input"
                        />
                        <label htmlFor="updateAdminLinkOriginForAllInput" className="text-muted">
                            update admin link origin for all apps?&nbsp;
                        </label>
                        <input
                            id="updateAdminLinkOriginForAllInput"
                            type="checkbox"
                            checked={this.state.updateAdminLinkOriginForAll}
                            onChange={this.handleUpdateAdminLinkOriginForAll}
                        />
                        <span className="app-setting-input-label">Admin Link Path&nbsp;</span>
                        <input
                            id="adminLinkInput"
                            value={this.state.adminLinkPath}
                            onChange={this.handleAdminLinkPathChange}
                            className="app-setting-text-input"
                        />
                        <span className="app-setting-input-label">Password Hash&nbsp;</span>
                        <input
                            id="passwordHashInput"
                            value={this.state.passwordHash}
                            onChange={this.handlePasswordHashChange}
                            type="text"
                            className="app-setting-text-input"
                        />
                        <label htmlFor="updatePwHashForAllInput" className="text-muted">
                            update password hash for all apps?&nbsp;
                        </label>
                        <input
                            id="updatePwHashForAllInput"
                            type="checkbox"
                            checked={this.state.updatePwHashForAll}
                            onChange={this.handleUpdatePwHashForAll}
                        />
                        <span className="app-setting-input-label">App Store Link:&nbsp;</span>
                        <input
                            id="appStoreLinkInput"
                            value={this.state.appStoreLink}
                            onChange={this.handleAppStoreLinkChange}
                            className="app-setting-text-input"
                        />
                        <span className="app-setting-input-label">App Key&nbsp;</span>
                        <input value={this.state.appKey} className="app-setting-text-input" readOnly />
                        {googleTable}
                        {appleTable}
                        {this.state.error && (
                            <div className="app-settings-submit-error alert-info" onClick={this.dismissError}>
                                <span>
                                    <button
                                        id="appSettingErrorState"
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={this.dismissError}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {this.state.error}
                                </span>
                            </div>
                        )}
                        <form className="app-setting-btn-group" onSubmit={this.handleSubmit}>
                            {saveButtonUi}
                            {cancelButtonUi}
                        </form>
                        {this.state.isDeleteAppClick && (
                            <Confirm
                                title={
                                    <span>
                                        Delete&nbsp;<b>{this.state.appName}</b>?
                                    </span>
                                }
                                message={
                                    <span>
                                        Are you sure you want to delete&nbsp;
                                        <b>{this.state.appName}</b>?
                                    </span>
                                }
                                onAbort={this.onAbortDeleteApp}
                                onConfirm={this.onConfirmDeleteApp}
                                confirmLabel="Continue"
                                abortLabel="Cancel"
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default AppSettingUi;
